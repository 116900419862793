<script setup lang="ts">
import type { ExternalUser } from '../../auth/entities/user';

const { setLicenseValidity, licenseValidity } = useContract();
const { disableTour } = useTour();
const { user, isExternalUser } = useUser();
const { getCurrentUserId } = useCompany();

const loaded = ref<boolean>(false);

onBeforeMount(async () => {
    await setLicenseValidity(
        isExternalUser.value ? (user.value as ExternalUser) : null,
    );
    loaded.value = true;
    disableTour.value = !licenseValidity.value.data.hasValidLicense;
    await getCurrentUserId();
});
</script>

<template>
    <BaseLayoutFull>
        <NuxtLoadingIndicator />
        <template #aside>
            <TheSidebar />
        </template>
        <template v-if="loaded">
            <MtbaseErrorPage v-if="licenseValidity.error" />
            <slot v-else-if="licenseValidity.data.hasValidLicense" />
            <ContractPage
                v-else
                :gdpr-consent="licenseValidity.data.hasGdprConsent"
            />
        </template>
    </BaseLayoutFull>
</template>

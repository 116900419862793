<script setup lang="ts">
const { t } = useLang();
const { fetchUserInfo } = useUserInfo();
const { isProductDateValid } = useContract();

withDefaults(defineProps<{ gdprConsent?: boolean }>(), {
    gdprConsent: false,
});

const showContactModal = ref(false);

const submitContactModalForm = () => {
    showContactModal.value = false;
};

const navigateToGdprContract = () => {
    navigateTo(`${useRuntimeConfig().public.businessPortal.url}/contracts`, {
        external: true,
    });
};

const navigateToOnlineShop = () => {
    navigateTo(
        `${
            useRuntimeConfig().public.businessPortal.url
        }/shop?product=talent-contacts-limited-20`,
        {
            external: true,
        },
    );
};

onMounted(async () => {
    await fetchUserInfo();
});
</script>

<template>
    <ContractModal
        v-if="showContactModal"
        :show-modal="showContactModal"
        @close="showContactModal = false"
        @submit="submitContactModalForm"
    />
    <div v-if="!gdprConsent" class="max-w-[1521px]">
        <div class="px-9 pt-6 lg:pt-14">
            <div class="border border-gray-200 bg-white px-5 py-7">
                <h1 class="text-lg">
                    {{ t('contract', 'locked') }}
                </h1>
                <p class="text-sm">
                    {{ t('contract', 'contractsNotAgreedInfo') }}
                </p>
                <p class="text-sm">
                    {{ t('contract', 'reset') }}
                </p>
                <BaseButton
                    class="mt-4"
                    is-small
                    @click="navigateToGdprContract"
                >
                    {{ t('contract', 'showContract') }}
                </BaseButton>
            </div>
        </div>
    </div>
    <div v-else class="h-full">
        <div class="flex h-full flex-col items-center justify-center p-5">
            <IllustrationNoResult
                class="w-2/3 md:w-1/3"
                data-testid="ContactErrorPage"
            />
            <div class="text-center">
                <h1 class="text-lg">
                    {{ t('contract', 'activate') }}
                </h1>
                <p class="mt-6 text-sm">
                    {{ t('contract', 'hasGDPRConsentInfo') }}
                </p>
                <p v-if="!isProductDateValid()" class="text-sm">
                    {{ t('contract', 'requestNowInfo') }}
                </p>
                <p v-else class="text-sm">
                    {{ t('contract', 'requestNowInfoWithoutOnlineShop') }}
                </p>
                <div class="mt-6 flex justify-center gap-5">
                    <BaseButton
                        variant="green-outline"
                        @click="showContactModal = true"
                    >
                        {{ t('contract', 'requestNow') }}
                    </BaseButton>
                    <BaseButton
                        v-if="!isProductDateValid()"
                        @click="navigateToOnlineShop"
                    >
                        {{ t('contract', 'buy20ContactPackage') }}
                    </BaseButton>
                </div>
            </div>
        </div>
    </div>
</template>
